import React from "react";
import kenco from "../../images/coffeevgangs.jpg";

const KencoPage = () => (
  <article className="background margin-top-2">
    <h2 className="feature pink">Kenco - Coffee vs. Gangs</h2>
    <p className="margin-top-1">
      <em>
        Planning Director at lead creative agency JWT, responsible for creative
        development, overarching campaign strategy that included TV/ATL,
        digital, social, and content activations, CSR alignment.
      </em>
    </p>
    <p>
      <em>Campaign Overview</em> We were given a very clear and ambitious brief
      to reverse the fortunes of the Kenco brand: we were asked to:
    </p>

    <ul>
      <li>Help Kenco make a real ethical difference</li>
      <li>Create truly powerful creative work</li>
      <li>Sell 11% more jars of Kenco coffee.</li>
    </ul>
    <img src={kenco} alt="Kenco Coffee vs Gangs" />
    <p>
      To answer this challenge, JWT looked to the source of production –
      Honduras. Honduras is one of the world’s largest coffee producing
      countries, and a key supplier of Kenco’s beans. But the coffee industry
      was facing a problem – not enough new recruits to sustain it. Honduras
      also has the world’s highest murder rate and one of the worst gang
      problems.
    </p>
    <p>
      JWT conceived Coffee vs. Gangs, a pioneering scheme, that would give young
      Hondurans a route out of the spiral into gang life by giving them the
      opportunity, training and financial support to become coffee farmers,
      providing them - for the first time - with a choice in life.
    </p>
    <p>
      The beauty of this idea was that it combined 2 critical factors ethical
      campaigns struggle to do:
    </p>
    <ul>
      <li>
        It dealt with human issues rather than the more usual environmental side
        of sustainability. It moved Kenco’s focus beyond sustainability to
        actually creating solutions which help make the world fairer
      </li>
      <li>
        The campaign allowed consumers to make a clear and direct link between
        their purchase of a Kenco product and the future of the farming
        community.
      </li>
    </ul>
    <p>
      <em>Results</em> In the final quarter of 2014 (one month after our 6 week
      TV campaign finished) 52% more jars of Kenco’s core range were sold than
      in 2013.
    </p>
    <p>
      The engagement rate achieved for content was between 1.2% and 3.2% which
      was well above the brand’s benchmark of 0.5%.
    </p>
    <p>
      The stories received well over 1 million views within a month of upload.
      For example, Juan’s story intro garnered 1,230,819 views in the first
      month.
    </p>
    <p>
      <a href="https://www.youtube.com/watch?v=GkyxpuSd5u40" target="blank_">
        YouTube
      </a>{" "}
      |{" "}
      <a href="http://coffeevsgangs.telegraph.co.uk/" target="blank_">
        Telegraph feature
      </a>
    </p>
    <p>
      <em>Awards</em>
      <ul>
        <li>IPA Effectiveness Awards 2016, Bronze</li>
      </ul>
    </p>
  </article>
);

export default KencoPage;
