import React from "react";

const CvAuckland = () => (
  <article className="margin-top-2 background-yellow padding-1 strip-padding-top">
    <h2 className="feature green boxed">
      Perm and long contract (Auckland, NZ)
    </h2>
    <p className="margin-top-1">
      <em>Strategic planner, Account Director and Producer 2003 – 2010</em>
    </p>

    <h3>Badger</h3>
    <p>Account Director / Planner</p>
    <p>2009 – 2010</p>
    <p></p>
    <p>
      Hotels.com – channel planning for international brand campaign, social
      media strategy and execution.
    </p>
    <p>
      Vector – ATL and BTL strategy – advertising,CRM, events, web, social media
      as well as brand and product positioning for their gas, electricity and
      fibre optic broadband products.
    </p>
    <h3>TribalDDB</h3>
    <p>Digital Producer</p>
    <p>2008</p>
    <p>The Warehouse Recruitment – employer brand strategy, web build.</p>
    <p>
      New Zealand Lotteries – development and implementation of MyLotto online
      brand. Website refresh, design of transactional site, kiosks, launch
      campaign and ongoing comms, kiosks and digital signage.
    </p>
    <h3>AIM Proximity</h3>
    <p>Digital Producer</p>
    <p>2006 – 2007</p>
    <p>Air New Zealand – email campaigns and microsites. </p>
    <p> Bank of New Zealand – campaign microsites and online comms.</p>
    <h3>Transcend</h3>
    <p>Owner</p>
    <p>2003 – 2006</p>
    <p>Vector – launch of fibre optic business broadband product.</p>
    <p>Oxfam New Zealand – redesign and development of website.</p>
  </article>
);

export default CvAuckland;
