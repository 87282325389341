import React from "react";
import shell02 from "../../images/power-of-sport.jpg";

const ShellSportPage = () => (
  <article>
    <h2 className="feature green">Shell Corporate - The Power of Sport</h2>
    <p className="margin-top-1">
      <em>
        Planning Director at lead creative agency JWT, responsible for idea
        development and overarching campaign strategy: oversaw work by
        supporting media, content and social agencies.
      </em>
    </p>
    <p>
      <em>Campaign overview</em> One of the world’s most significant energy
      challenges is that by 2050 the global population will hit 9 billion. Shell
      wanted to show how it could help meet these future energy demands. With
      younger consumers particularly sceptical about the claims of big
      corporates, Shell needed a solution that engaged with their passions to
      get them to engage.{" "}
    </p>
    <p>
      The JWT solution was the “Power of Sport” project, harnessing energy from
      an unexpected source: the passion and movement of people who play
      football. The project provided an innovative solution for one Rio
      community while inspiring a global audience to think differently about
      energy.
    </p>
    <img src={shell02} alt="Shell The Power of Sport" />

    <p>
      <em>Results</em> The impact was felt globally. People now think
      differently about Shell, with significant increases in brand familiarity
      (+12%), “working to provide a sustainable energy future” (+8.0%) and “a
      leader in providing innovative energy solutions” (+8.1%).
    </p>
    <p>
      <a href="https://www.youtube.com/watch?v=KzONtMnAmPA" target="blank_">
        YouTube
      </a>
    </p>
    <p>
      <em>Awards</em>
      <ul>
        <li>
          Cannes Lions 2015 | Bronze Media Lion: Corporate Image & Communication
        </li>
        <li>M&M Awards 2015 | Winner: International Creativity Award</li>
      </ul>
    </p>
  </article>
);

export default ShellSportPage;
