import React from "react"

import Layout from "../components/layout"

const ContactPage = () => (
    <Layout>
        contact page
    </Layout>
)

export default ContactPage
