import React from "react";

const CvLondon = () => (
  <article className="background-yellow padding-1 strip-padding-top">
    <h2 className="feature blue boxed">Pohutukawa Ltd (London, UK)</h2>
    <p className="margin-top-1">
      <em>Strategic Digital Consultant 2010 – 2020</em>
    </p>
    <h3>Wunderman Thompson</h3>
    <p>Nestle Purina EMEA – UX and content strategy, IA.</p>
    <h3>Nonsense</h3>
    <p>AXA, CRUK – campaigns, comms and social strategy.</p>
    <h3>Space</h3>
    <p>Birra Moretti – idea/creative development.</p>
    <h3>Brave</h3>
    <p>The Glenlivet – idea/creative development.</p>
    <h3>AML</h3>
    <p>AXA IM – CX and UX strategy, digital transformation, EMEA.</p>
    <h3>JWT New York (MIRUM)</h3>
    <p>
      United Healthcare – business strategy, brand strategy and positioning,
      EMEA.
    </p>
    <h3>BMB</h3>
    <p>Samsung TV – marketing strategy, social strategy.</p>

    <h3>The Organic Agency</h3>
    <p>Strategy training programme for team of 5 planners/UXs.</p>
    <h3>George and Dragon</h3>
    <p>
      Highways England, Dept of Education, NHS Online, Kwik-Fit and the Royal
      Academy of Engineering, NFU Mutual.
    </p>
    <h3>Kicca Media</h3>
    <p>
      Strategy consultant for start-up sports media and content marketing
      agency.
    </p>
    <h3>Leo Burnett</h3>
    <p>Lurpak – planning and campaigns.</p>
    <p>McDonalds – ATL and digital campaigns.</p>
    <p>Kleinwort Benson – mobile app and launch comms.</p>
    <p>Avios – CRM.</p>
    <h3>AKQA</h3>
    <p>
      NIVEA for 2016 planning across priority product ranges within skincare
      category.
    </p>
    <h3>Razorfish</h3>
    <p>Codemasters/Intel F1 2015 game launch.</p>
    <h3>Landor Associates</h3>
    <p>
      Bayer AG – brand development and strategy, employee engagement planning.
    </p>
    <h3>Bostock and Pollitt</h3>
    <p>GE, BT and Barratt London.</p>
    <h3>JWT London</h3>
    <p>
      Listerine – brand and digital strategy, idea/creative development. Benylin
      – digital strategy and idea/creative and content. Sudafed – digital
      strategy and idea/creative and content.
    </p>
    <p>Shell – digital strategic lead across all key business areas:</p>
    <p>
      Lubricants – <em>Driven to Extremes</em> AFP.
    </p>
    <p>
      Corporate – <em>Power of Sport</em> – motorsports (F1 sponsorship) and
      recruitment.
    </p>
    <p>
      Kenco – defining strategic framework for <em>Coffee vs Gangs</em>{" "}
      campaign.
    </p>
    <p>
      British Army Recruitment – service design, digital strategy, integration.
    </p>
    <h3>Radley Yeldar</h3>
    <p>
      GSK corporate and pharmaceutical brands – global digital, content, UX and
      comms strategy.
    </p>

    <h3>McCann Erickson</h3>
    <p>
      Xbox – mobile app (3 platforms) – digital strategy and implementation
      planning.
    </p>
    <h3>Proximity London</h3>
    <p>
      Orange – social media strategy research proposal and strategic framework, 
      mobile web strategy, user experience.
    </p>
    <h3>Arnold KLP</h3>
    <p>Total – online rewards programme.</p>
    <h3>Exposure</h3>
    <p>
      Westfield – comms strategy, including digital, live events and
      experiential.
    </p>
  </article>
);

export default CvLondon;
