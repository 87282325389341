import React from "react";

const CvIntro = () => (
  <article>
    <h2 className="feature blue">
      Entrepreneurial integrated strategist providing leadership, direction and
      expertise on brand strategy and digital transformation.
    </h2>
    <div>
      <ul>
        <li>
          Data-driven insights, creative and campaign development for global
          brands to effect behaviour change, to drive sales
        </li>
        <li>
          Significant experience working with C-Suite clients to help solve
          complex business problems
        </li>
        <li>
          Extensive experience of commissioning research, running focus groups
          and workshops
        </li>
        <li>
          Brand strategy, CX, UX, IA, service design and content strategy for
          global and enterprise web platforms.
        </li>
      </ul>
    </div>
    <div>
      <p>BA (Hons) Economics/Politics – Leeds University</p>
      <h2>Awards</h2>
      <ul>
        <li>IPA Effectiveness Awards 2016 – Kenco Coffee</li>
        <li>Cannes Lions 2015 – Shell Power of Sport</li>
        <li>M&M Award 2014 – Shell Rimula Hidden Heroes</li>
        <li>M&M Award 2014 – Shell Advance Freedom Riders</li>
        <li>M&M Award 2013 – Shell Driven to Extremes</li>
        <li>2008 Webby nominee – The Warehouse Group Careers</li>
      </ul>
    </div>
  </article>
);

export default CvIntro;
