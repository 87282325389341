import React, { Component } from "react";
import "./styles/lotte.scss";
import { Route, HashRouter } from "react-router-dom";
import MainPage from "./pages/main";
import ContactPage from "./pages/contact";
import CaseStudiesPage from "./pages/case-studies";
import CvPage from "./pages/cv";
import Layout from "./components/layout";

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Route path="/" exact component={Main} />
        <Route path="/pages/contact/" component={ContactPage} />
        <Route path="/pages/case-studies/" component={CaseStudiesPage} />
        <Route path="/pages/cv/" component={CvPage} />
      </HashRouter>
    );
  }
}

function Main() {
  return (
    <Layout>
      <MainPage />
    </Layout>
  );
}

export default App;
