import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

class Navigation extends Component {
  render() {
    return (
      <nav>
        <ul>
          <li>
            <Link to="/">Intro</Link>
          </li>
          <li>
            <Link to="/pages/case-studies">Case studies</Link>
          </li>
          <li>
            <Link to="/pages/cv">CV</Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
