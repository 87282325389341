import React from "react";

import Layout from "../components/layout";
import CvLondon from "./cv/london";
import CvAuckland from "./cv/auckland";

const CvPage = () => (
  <Layout>
    <CvLondon />
    <CvAuckland />
  </Layout>
);

export default CvPage;
