import React from "react";
import shell01 from "../../images/shell.jpg";

const ShellExtremePage = () => (
  <article class="margin-top-2">
    <h2 className="feature blue">Shell Lubricants - Driven to Extremes</h2>
    <p className="margin-top-1">
      <em>
        Planning Director at lead creative agency JWT, responsible for
        overarching campaign strategy that included AFP, digital, social, and
        content activations on a global scale.
      </em>
    </p>
    <p>
      <em>Campaign overview</em> Motor oil is dull. It may be a motoring
      essential but it’s often an “invisible” purchase, with brand selection
      undertaken by the local garage. But sometimes motorists are forced to make
      a choice and, when they do, we needed them to choose Helix. We realised
      that drivers only pay attention to motor oil at one key moment: when
      preparing for extreme journeys or ahead of long family trips.{" "}
    </p>
    <img src={shell01} alt="Shell Extreme" />
    <p>
      The solution was “Driven to Extremes”, a 3-part TV series designed to
      showcase the toughest driving challenges ever conceived with Shell Helix
      as the star. Driven to Extremes was an Advertising Funded Program that JWT
      developed for Discovery Channel. It starred Tom Hardy, Henry Cavill and
      Adrien Brody It aired in 90 markets and resulted in Shell Helix winning
      'Best Automotive Campaign' in the 2013 Global M&M awards results.
    </p>
    <p>
      We provided unmissable entertainment, exhilarating adventure, motorsport,
      and techie “know it all” ammunition in a single show. We’d take
      celebrities and put them in the toughest driving conditions known to man.
    </p>
    <p>
      Additional detail, including behind the scenes footage, celebrity
      interviews and technical info, distributed via YouTube. We also bought the
      idea to life via a series of mobile and mini-games on mobile and Facebook.
    </p>
    <p>
      <em>Results</em> Consumers who saw our content were 50% more likely to say
      Shell Helix was a unique motor oil brand. Top of mind awareness rose
      three-fold or more in some markets and consumers claimed they were also
      30-40% more likely to purchase Shell Helix. NB sales information is
      commercially sensitive, and therefore not available.
    </p>
    <p>
      <a href="https://www.youtube.com/watch?v=ACfbytLj4nM" target="blank_">
        YouTube
      </a>
    </p>

    <p>
      <em>Awards</em>
    </p>
    <ul>
      <li>
        AdForum 2015 | Winner: AdForum Greatest Hits: Experiential, Branded
        Content, Stunt
      </li>
      <li>M&M Global Awards 2013 | Winner: Best Automotive Campaign</li>
    </ul>
  </article>
);

export default ShellExtremePage;
