import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

class Header extends Component {
  render() {
    return (
      <header>
        <div className="header-content flex-group space-between">
          <h1 className="padding-left-1">
            <Link to="/">Kevin Stables</Link>
          </h1>
          <article className="flex-align-center">Tel. 021 108 4088</article>
        </div>
      </header>
    );
  }
}

export default Header;
