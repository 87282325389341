import React from "react";

import Layout from "../components/layout";
import ShellSportPage from "./case-studies/shell-sport";
import KencoPage from "./case-studies/kenco-coffee";
import ShellExtremePage from "./case-studies/shell-extreme";

const CaseStudiesPage = () => (
  <Layout>
    <section>
      <ShellSportPage />
      <KencoPage />
      <ShellExtremePage />
    </section>
  </Layout>
);

export default CaseStudiesPage;
