import React from "react";
import CvIntro from "./intro";

const MainPage = () => (
  <>
    <CvIntro />
  </>
);

export default MainPage;
